<template>
  <Header />
  <div class="pt-[58px]" v-if="siteOptions">
    <slot />
  </div>
  <Footer />
  <Message />
</template>

<script setup>
const user = useSupabaseUser()
const supabase = useSupabaseClient()
const optionsStore = useOptionsStore()
const messageStore = useMessageStore()
const { fetchProfile, fetchNotifications } = useUser()

const siteOptions = ref(null)

setTimeout(() => {
  messageStore.clearMessage()
}, 3000)

const { data: options, error } = await useAsyncData(
  'site-options', 
  async () => {
    const { data } = await supabase.from('site_options').select('*').maybeSingle()
    return data
  }
)

if (!options.value) {
  throw createError({
    statusCode: 404
  })
}

siteOptions.value = options.value

optionsStore.updateOptions(options.value)

watch(user, async () => {
  if (user.value) {
    await fetchProfile()
  }
})

</script>